import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {firstview} from './index';
import {vues} from './vues';
import {talent_single} from './talent';
import {auditionLp} from './audition-lp';

document.addEventListener('DOMContentLoaded', function() {
    vues();
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	scrolEvents(controller , scene);
    firstview();
    talent_single();
	auditionLp(controller , scene);
});