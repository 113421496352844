import Swiper from 'swiper';
//import particlesJS from 'particles.js';

export function talent_single(){
    if(document.getElementById('talent-swiper')){
        let thumb = document.getElementById('thumbread');
        let thumb_text = thumb.innerHTML.trim();
        let thumb_array = thumb_text.split(',');        

        const galleryThumbs = new Swiper('.gallery-top', {
            spaceBetween: 0,
            slidesPerView: 1,
            pagination: {
                bulletClass:'swiper-pagination-thumb-default',
                bulletActiveClass:'swiper-pagination-thumb-active',
                el: '.swiper-pagination-thumbs',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '" style="background-image:url('+ thumb_array[index] +');"></span>';
                },
            },
        });
    }    
}